import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { colors, md } from '../common'

export default () => (
  <StaticQuery
    query={graphql`
      query StoneQuery {
          allBirthstonesJson {
            edges {
              node {
                month_
                image
                text
              }
            }
          }
      }
      `}
      render={ data => (
        <Wrap>
            {data.allBirthstonesJson.edges.map((stone, index) => {
            return  <Card key={index}>
                      <img css={`width:100%;`} src={stone.node.image} alt="award icon" />
                      <p css={`color:${colors.green};font-size:20px;text-transform:uppercase;`}>{stone.node.month_}</p>
                      <br />
                      <div dangerouslySetInnerHTML={{__html:stone.node.text}} />
                    </Card>
          })}
        </Wrap>
      )}
  />
)

const Card = styled.div`
  width: 26%;
  text-align:center;
  margin: .5rem;
  background-color:white;
  padding: 2rem;
  font-size:14px;
  color: ${colors.greyDarkest};
  line-height: 20px;

  ${md} {
    width:100%;
  }
`

const Wrap = styled.div`
  background-color: ${colors.greyLight};
  padding: 4rem;
  display:flex;
  flex-wrap: wrap;
  justify-content:space-around;

  ${md} {
    padding: 1rem;
  }
`
