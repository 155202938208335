import React, { useState } from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import { PageHead, GreenBtn } from '../common'
import Modal from '../components/modal'
import GreenBar from '../sections/wa-greenBar3'
import Birthsones from '../sections/birthstones'
import { GreyBtn, colors } from '../common'
import Contact from '../components/contactForm'

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
  }

  return(
  <Layout>
    <SEO
      title="Colored Stones"
      description="Underwood’s in Jacksonville and Ponte Vedra, FL carries rubies, sapphires, emeralds, topaz, tourmalines, spinel and many more, set in beautifully crafted jewelry. Rarer gems available upon request" />
    <PageHead>
      <h1>Colored Stones</h1>
      <p>Gemstones come in every color, shape and size and we have a large selection of colored gemstone jewelry in all of our stores in Jacksonville and Ponte Vedra Beach, FL. Every year our gemologists attend the largest colored gemstone show in the World in Tucson, Arizona and hand pick colored stones of all varieties which we use to custom make jewelry using our Underwood’s Designs. We also feature designers like Erica Courtney and Spark Creation’s one of a kind collections.</p>
      <p>If you are looking for a rare, fine gemstone such as a Paraiba tourmaline, Trapiche emerald, Padparadscha sapphire or Alexandrite we can find it for you.</p>
      <GreenBtn onClick={handleClick}>Request to view a specific gemstone</GreenBtn>
    </PageHead>
    <GreenBar />
    <div css={`text-align:center;padding:2rem;background-color:${colors.greyLight};color:${colors.greyDarker};`}>
      <h2>Colored Gemstone Jewelry</h2>
      <p css={`padding:2rem;`}>Find the perfect colored gemstone jewelry piece from one of our vibrant designers. Erica Courtney, Spark Creations, and Underwood’s provide beautiful options that are perfect for any occasion.</p>
      <Link to="/products/colored-gemstones"><GreyBtn>Browse Colored Stone Jewelry</GreyBtn></Link>
    </div>
    <Birthsones />
    <JoinUs />

    <Modal show={ show } toggle={ handleClick } mobile={true}>
      <Contact />
    </Modal>
  </Layout>
)}
